import { useState, useContext, createContext } from 'react'

const GlobalContext = createContext();

/** contexto que contiene las variables de uso para preventa */
export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export const GlobalStatesProvider = ({ children }) => {

  /**State de cliente en donde se guarda toda la informacion actual de este,
   *  si ha inciado sesion o si esta en registro.*/
  const [clienteState, modificarClienteState] = useState({
    Registro: false,
    Accion: "Recuperar",
    Sesion: false,
    TipoDocumento: "",
    NumeroDocumento: "",
    Nombres: "",
    Apellidos: "",
    Sucursal: "0",
    Correo: "",
    Celular: "",
    FechaNacimiento: "",
    Genero: "",
    Pais: "",
    Ciudad: "",
    Departamento: "",
    Direccion: "",
    Barrio: "",
  });

  return (
    <GlobalContext.Provider value={
      {
        clienteState,
        modificarClienteState
      }
    }>
      {children}
    </GlobalContext.Provider>
  )
}